<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Обслуживающая организация
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text"
            >Данные обслуживающей организации</FormCardTitle
          >
          <FormInputBlock>
            <ValidationDataNameField
              v-model="value"
              rules="required"
              @input="dadataField"
              label="Название/ИНН"
            />
            <ValidationInputField
              rules="required"
              label="Полное название"
              v-model="full_org_name"
              validate-name="полное название"
            >
            </ValidationInputField>
            <ValidationAutocompleteField
              :search-function="citySearch"
              rules="required"
              label="Город"
              v-model="city"
              :return-object="false"
              validate-name="город"
            />
            <ValidationSelectField
              v-model="ownership"
              :items="[
                { text: 'ИП', value: 'ИП' },
                { text: 'ПАО', value: 'ПАО' },
                { text: 'НАО', value: 'НАО' },
                { text: 'ООО', value: 'ООО' }
              ]"
              rules="required"
              label="Тип организации"
              validate-name="тип организации"
            ></ValidationSelectField>
            <ValidationInputField
              v-model="address"
              rules="required"
              label="Юр. адрес"
              validate-name="юр. адрес"
            ></ValidationInputField>
            <ValidationInputField
              rules="required"
              v-model="actual_address"
              label="Фактический адрес"
            />
            <ValidationInputField
              v-model="phone_number"
              mask="+7 (###) ###-##-##"
              rules="required|all_phone"
              label="Телефон"
              validate-name="телефон"
            ></ValidationInputField>
            <ValidationInputField
              rules="required|email"
              v-model="email"
              label="E-mail"
              validate-name="E-mail"
            ></ValidationInputField>
            <ValidationInputField v-model="director" label="Руководитель" />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->

        <!--    Информация о договоре    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Информация о договоре</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              v-model="number"
              rules="required"
              label="Номер договора"
            />
            <ValidationFileField
              is-doc
              accept=".docx,.doc,.pdf"
              v-model="file"
              :rules="'required'"
              label="Файл"
            />
          </FormInputBlock>
        </div>
        <!--    Информация о договоре    -->

        <!--   Поиск Администраторы    -->
        <div>
          <FormCardTitle>
            <p class="data__text">
              Выбрать администратора сервисной организации из существующих
              пользователей
            </p>
          </FormCardTitle>

          <FormInputBlock v-if="searchAdmins.length === 0">
            <button class="btn btn-green" @click="addSearchAdmin">
              Выбрать администратора
            </button>
          </FormInputBlock>
          <FormInputBlock
            v-for="n in searchAdmins.length"
            :key="n"
            is-deletable
            @delete="deleteSearchAdmin(n - 1)"
          >
            <ValidationAutocompleteField
              return-object
              :searchFunction="userSearch"
              :value="searchAdmins[n - 1]"
              @input="setSearchAdminValue(n - 1, $event)"
              label="Телефон"
              rules="required"
            />
            <button
              class="btn btn-green"
              @click="addSearchAdmin"
              v-if="n === searchAdmins.length"
            >
              Выбрать администратора
            </button>
          </FormInputBlock>
        </div>
        <!--    Поиск Администраторы    -->

        <!--    Создать Администраторы    -->
        <div>
          <FormCardTitle>
            <p class="data__text">
              Создать нового пользователя в качестве администратора сервисной
              организации
            </p>
          </FormCardTitle>
          <FormInputBlock v-if="createAdmins.length === 0">
            <button class="btn btn-green" @click="addCreateAdmin">
              Добавить администратора
            </button>
          </FormInputBlock>
          <FormInputBlock
            v-for="n in createAdmins.length"
            :key="n"
            is-deletable
            @delete="deleteCreateAdmin(n - 1)"
          >
            <ValidationInputField
              :value="createAdmins[n - 1].phone_number"
              mask="+7 (9##) ###-##-##"
              @input="setCreateAdminValue(n - 1, 'phone_number', $event)"
              label="Телефон"
              rules="required|phone|uniquePhone"
            />
            <div />
            <div />
            <ValidationInputField
              :value="createAdmins[n - 1].last_name"
              @input="setCreateAdminValue(n - 1, 'last_name', $event)"
              label="Фамилия"
              rules="required"
            />
            <ValidationInputField
              :value="createAdmins[n - 1].first_name"
              @input="setCreateAdminValue(n - 1, 'first_name', $event)"
              label="Имя"
              rules="required"
            />
            <ValidationInputField
              :value="createAdmins[n - 1].middle_name"
              @input="setCreateAdminValue(n - 1, 'middle_name', $event)"
              label="Отчество"
              rules="required"
            />
            <button
              class="btn btn-green"
              @click="addCreateAdmin"
              v-if="n === createAdmins.length"
            >
              Добавить администратора
            </button>
          </FormInputBlock>
        </div>
        <!--    Создать Администраторы    -->

        <!--    Реквизиты    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Реквизиты</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required|min:10"
              mask="##########"
              validate-name="ИНН"
              v-model="TIN"
              label="ИНН"
            />
            <ValidationInputField
              :rules="ownership == 'ИП' ? 'required|min:15' : 'required|min:13'"
              :mask="ownership == 'ИП' ? '###############' : '#############'"
              validate-name="ОГРН"
              label="ОГРН"
              v-model="PSRN"
            />
            <ValidationInputField
              rules="required|min:9"
              validate-name="КПП"
              mask="#########"
              v-model="IEC"
              label="КПП"
            />
            <ValidationInputField
              rules="required|min:9|max:9"
              mask="#########"
              validate-name=""
              v-model="RCBIC"
              label="БИК"
            />
            <ValidationInputField
              rules="required"
              v-model="bank"
              validate-name="название банка"
              label="Название банка"
            />
            <ValidationInputField
              rules="required|min:20"
              v-model="CA"
              label="К/с"
              mask="####################"
            />

            <ValidationInputField
              rules="required|min:20"
              v-model="giro"
              label="Р/c"
              mask="####################"
            />
          </FormInputBlock>
        </div>
        <!--    Реквизиты    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createService)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import MainLayout from "@/components/layouts/MainLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import { getCitiesClearRequest } from "@/helpers/api/cities";
import ValidationDataNameField from "@/components/ValidationField/ValidationDataNameField";
import { getAllUsersClearRequest } from "../../helpers/api/user";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "CreateServiceOrganization",
  components: {
    ValidationDataNameField,
    ValidationFileField,
    ValidationAutocompleteField,
    ValidationSelectField,
    ValidationInputField,
    MainLayout,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout
  },
  data() {
    return {
      value: null,
      loading: false,
      userSearch: async value => {
        return (
          await getAllUsersClearRequest({ query: { search: value } })
        ).data.results.map(el => ({
          text: `${el.phone_number}, ${el.full_name}` || "",
          value: el
        }));
      },
      citySearch: async value => {
        return (
          await getCitiesClearRequest({
            search: value,
            offset: null
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    currentCompany: {
      get() {
        return this.$store.getters.getCurrentOrganization;
      }
    },
    getCurrentRole: {
      get() {
        return this.$store.getters.getCurrentRole;
      }
    },
    number: {
      get() {
        return this.$store.getters.getContractsForm.number;
      },
      set(newVal) {
        this.$store.commit("setContractsFormValue", {
          fieldName: "number",
          value: newVal
        });
      }
    },
    file: {
      get() {
        return this.$store.getters.getContractsForm.file;
      },
      set(newVal) {
        this.$store.commit("setContractsFormValue", {
          fieldName: "file",
          value: newVal
        });
      }
    },
    name: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.name;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "name",
          value: newValue
        });
      }
    },
    full_org_name: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.full_org_name;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "full_org_name",
          value: newValue
        });
      }
    },
    director: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.director;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "director",
          value: newValue
        });
      }
    }, //директор
    ownership: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.ownership;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "ownership",
          value: newValue
        });
      }
    }, //"ИП", "ООО" и тд
    TIN: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.TIN;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "TIN",
          value: newValue
        });
      }
    }, //инн
    PSRN: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.PSRN;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "PSRN",
          value: newValue
        });
      }
    }, //ОГРН
    IEC: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.IEC;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "IEC",
          value: newValue
        });
      }
    }, //КПП
    CA: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.CA;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "CA",
          value: newValue
        });
      }
    }, //"Корреспондентский счёт"
    giro: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.giro;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "giro",
          value: newValue
        });
      }
    }, //Расчётный счёт
    RCBIC: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.RCBIC;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "RCBIC",
          value: newValue
        });
      }
    }, //БИК
    bank: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.bank;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "bank",
          value: newValue
        });
      }
    }, //Название банка
    address: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.address;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "address",
          value: newValue
        });
      }
    }, //Адрес
    phone_number: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.phone_number;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    email: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.email;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "email",
          value: newValue
        });
      }
    },
    city: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.city;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "city",
          value: newValue
        });
      }
    }, //id города
    acquiring_provider: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.acquiring_provider;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "acquiring_provider",
          value: newValue
        });
      }
    }, //Эквайринг
    actual_address: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.actual_address;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "actual_address",
          value: newValue
        });
      }
    },
    acquiring_entity: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.acquiring_entity;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "acquiring_entity",
          value: newValue
        });
      }
    }, //Организация принимающая платежи
    diller: {
      get() {
        return this.$store.getters.getOrganizationCreateForm.diller;
      },
      set(newValue) {
        this.$store.commit("setOrganizationCreateForm", {
          fieldName: "diller",
          value: newValue
        });
      }
    }, //Организация принимающая платежи
    createAdmins: {
      get() {
        return this.$store.getters.getOrganizationCreateAdminForm.createUsers;
      }
    },
    searchAdmins: {
      get() {
        return this.$store.getters.getOrganizationCreateAdminForm.foundUsers;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearCreateOrganizationForm");
    next();
  },
  mounted() {
    this.$store.commit("setOrganizationCreateForm", {
      fieldName: "type",
      value: "service"
    });
    this.diller = this.currentCompany;
  },
  methods: {
    dadataField(value) {
      if (typeof value === "string") this.name = value;
      if (!value || typeof value.value !== "object") return;
      this.address = value.value.data.address.value;
      this.actual_address = value.value.data.address.value;
      this.name = value.text;
      this.TIN = value.value.data.inn;
      this.full_org_name = value.value.data.name.full_with_opf;
      this.PSRN = value.value.data.ogrn;
      if (value.value.data.kpp) this.IEC = value.value.data.kpp;
      else this.IEC = "";
    },
    setSearchAdminValue(index, value) {
      this.$store.commit("setSearchCreateAdmin", { index, admin: value });
    },
    setCreateAdminValue(index, fieldName, value) {
      this.$store.commit("setCreateAdminValue", { index, fieldName, value });
    },
    deleteSearchAdmin(index) {
      this.$store.commit("removeSearchCreateAdmin", { index: index });
    },
    addSearchAdmin() {
      this.$store.commit("addSearchCreateAdmin");
    },
    deleteCreateAdmin(index) {
      this.$store.commit("removeCreateAdmin", { index: index });
    },
    addCreateAdmin() {
      this.$store.commit("addCreateAdmin");
    },
    createService() {
      this.loading = true;
      this.$store
        .dispatch("createCompany")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.serviceOrganization)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
